import {
    awsinstance
} from './api-config'

import {AWS_API_GATEWAY_URL} from '../env.json';

export const fetchData = () => {
    return awsinstance.get(`${AWS_API_GATEWAY_URL}/data`)
}

export const addData = (data) => {
    return awsinstance.post(`${AWS_API_GATEWAY_URL}/add-data`, data, {
        headers: { 'Content-Type': 'application/json' }
    })
}

export const editData = (data) => {
    return awsinstance.post(`${AWS_API_GATEWAY_URL}/edit-data`, data, {
        headers: { 'Content-Type': 'application/json' }
    })
}

export const fetchAutoCompleteData = () => {
    return awsinstance.get(`${AWS_API_GATEWAY_URL}/auto-complete`)
}

export const fetchFilterValues = () => {
    return awsinstance.get(`${AWS_API_GATEWAY_URL}/filter-values`)
}

// export const addBike = (data, user_id) => {
//     return instance.post(`${API_URL}/bikes/add/${user_id}`, data, {
//       headers: { 'Content-Type': 'application/json' }
//     })
// }

