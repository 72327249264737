import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {fetchUser} from '../../api'

import './styles.css'

import {columns} from './cols'

import { Table } from 'antd';
import DeleteUser from './deleteUser';

import {useSelector} from 'react-redux'

export default function User(){

    let { username } = useParams();

    const [viewUser, setViewUser] = useState(null);

    const user = useSelector((state) => state.user);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (username && username !== "" && user && user !== null){
            fetchUser(user.username, username)
            .then(res=>{
                setViewUser(res.data.data)
            })
            .catch(err=>{
                console.log(err)
            })
        }
    }, [user, username])

    const handleDelete = (e) => {
        setVisible(true);
    }

    return (
        viewUser && (
            <div className="bike-container">
            <div className="bike-info-container">
                <h2>Username: {viewUser.username}</h2>
                <div className="delete-button" onClick={handleDelete}>DELETE</div>
                <DeleteUser 
                visible={visible} 
                setVisible={setVisible} 
                user={viewUser}
                username={user.username}/>
            </div>
            <div className="bike-reservations-container">
                <h3>Reservations:</h3>
                <p>Total: {viewUser.reservations.length}</p>
                <Table 
                dataSource={viewUser.reservations} 
                columns={columns} 
                rowKey={'id'}
                scroll={{ y: 200, x: 0 }}
                />
            </div>
        </div>
        )
    )
}