import { DataActionTypes } from './data.types';

const INITIAL_STATE = {
	ranks: null,
    priorities: null,
    categories: null,
    users: null,
    titles: null,
    companies: null,
	contact_persons: null,
	areas: null,
	ac_companies: null,
	clients: null
};

const dataReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		case DataActionTypes.SET_MASTER_DATA: 
			return {
				...state,
				ranks: action.payload.ranks,
                categories: action.payload.categories,
                titles: action.payload.titles,
                users: action.payload.users,
                priorities: action.payload.priorities,
				areas: action.payload.areas,
				companies: action.payload.companies
			}

		case DataActionTypes.SET_CLIENT_DATA:
			return {
				...state,
				clients: action.payload.clients
			}

        case DataActionTypes.SET_AUTOCOMPLETE_DATA: 
			return {
				...state,
				ac_companies: action.payload.ac_companies,
				contact_persons: action.payload.contact_persons
			}

		case DataActionTypes.SET_LOGOUT:
			return INITIAL_STATE

		default:
			return state;
	}
};

export default dataReducer;
