import moment from 'moment';

export const columns = [
    {
        title: 'S. No',
        dataIndex: '',
        key: '',
        align: 'center',
        width: 60,
    },
    {
        title: 'Client Code',
        dataIndex: 'client_code',
        key: 'client_code',
        align: 'center',
        width: 100,
        sorter: (a, b) => a.client_code.localeCompare(b.client_code),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        align: 'center',
        width: 300,
        sorter: (a, b) => a.company.localeCompare(b.company),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        align: 'center',
        width: 230,
        onFilter: (value, record) => record.priority.indexOf(value) === 0,
        sorter: (a, b) => a.priority.localeCompare(b.priority),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Current Handler',
        dataIndex: 'current_handler',
        key: 'current_handler',
        align: 'center',
        width: 120,
        onFilter: (value, record) => record.current_handler.indexOf(value) === 0,
        sorter: (a, b) => a.current_handler.localeCompare(b.current_handler),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Previous Handler',
        dataIndex: 'previous_handler',
        key: 'previous_handler',
        align: 'center',
        width: 125,
        onFilter: (value, record) => record.previous_handler.indexOf(value) === 0,
        sorter: (a, b) => a.previous_handler.localeCompare(b.previous_handler),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Area',
        dataIndex: 'area',
        key: 'area',
        align: 'center',
        width: 130,
        onFilter: (value, record) => record.area.indexOf(value) === 0,
        sorter: (a, b) => a.area.localeCompare(b.area),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        align: 'center',
        width: 140,
        onFilter: (value, record) => record.category.indexOf(value) === 0,
        sorter: (a, b) => a.category.localeCompare(b.category),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Rank',
        dataIndex: 'rank',
        key: 'rank',
        align: 'center',
        width: 80,
        onFilter: (value, record) => record.rank.indexOf(value) === 0,
        sorter: (a, b) => a.rank.localeCompare(b.rank),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        align: 'center',
        width: 100,
        onFilter: (value, record) => record.title.indexOf(value) === 0,
        sorter: (a, b) => a.title.localeCompare(b.title),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Contact Person',
        dataIndex: 'contact_person',
        key: 'contact_person',
        align: 'center',
        width: 100,
        sorter: (a, b) => a.contact_person.localeCompare(b.contact_person),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation',
        align: 'center',
        width: 120,
        sorter: (a, b) => a.designation.localeCompare(b.designation),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        align: 'center',
        width: 200,
        sorter: (a, b) => a.address.localeCompare(b.address),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Address 2',
        dataIndex: 'address2',
        key: 'address2',
        align: 'center',
        width: 200,
        sorter: (a, b) => a.address2.localeCompare(b.address2),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'City/Pincode',
        dataIndex: 'city_pincode',
        key: 'city_pincode',
        align: 'center',
        width: 150,
        sorter: (a, b) => a.city_pincode.localeCompare(b.city_pincode),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Telephone',
        dataIndex: 'tel',
        key: 'tel',
        align: 'center',
        width: 200,
        sorter: (a, b) => a.tel.localeCompare(b.tel),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
        align: 'center',
        width: 150,
        sorter: (a, b) => a.mobile.localeCompare(b.mobile),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        align: 'center',
        width: 300,
        sorter: (a, b) => a.email.localeCompare(b.email),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Mobile 2',
        dataIndex: 'mobile2',
        key: 'mobile2',
        align: 'center',
        width: 200,
    },
    {
        title: 'Extras',
        dataIndex: 'extras',
        key: 'extras',
        align: 'center',
        width: 200,
    },
    {
        title: 'No Meeting',
        dataIndex: 'no_meeting',
        key: 'no_meeting',
        align: 'center',
        width: 100,
        sorter: (a, b) => a.no_meeting - b.no_meeting,
        filters: [
            {"text": "Yes",
            "value": true,},
            {
                "text": "No",
                "value": false
            }
        ],
        onFilter: (value, record) => record.no_meeting === value,
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Telephonically',
        dataIndex: 'telephonically',
        key: 'telephonically',
        align: 'center',
        width: 140,
        sorter: (a, b) => a.telephonically - b.telephonically,
        defaultSortOrder: 'ascend',
        filters: [
            {"text": "Yes",
            "value": true,},
            {
                "text": "No",
                "value": false
            }
        ],
        onFilter: (value, record) => record.no_meeting === value,
    },
]
