import { UserActionTypes } from './user.types';
import { DataActionTypes } from '../data/data.types';


import {
	getUserfromApiGateway
} from '../../api'

import { Auth } from 'aws-amplify';

const flushError = () => async (dispatch) => {
	dispatch({
		type: UserActionTypes.SET_LOGIN_ERROR,
		payload: {
			loginError: "",
		}
	});
}

const setCurrentUser = () => async (dispatch) => {
	let user = localStorage.getItem('user')
    if (user){
		const user = await Auth.currentAuthenticatedUser()
		getUserfromApiGateway()
		.then(res=>{
			dispatch({
				type: UserActionTypes.SET_CURRENT_USER,
				payload: {
					cognitoUser: user,
					userData: res.data
				}
			});
		}).catch(err=>{
			dispatch({
				type: UserActionTypes.SET_LOGIN_ERROR,
				payload: {
					loginError: "Unauthorized.",
				}
			});
		})
    }
}

const setSignin = (username, password, push) => async (dispatch) => {
	try {
		const user = await Auth.signIn(username, password);
		if (user.challengeName === "NEW_PASSWORD_REQUIRED"){
			dispatch({
				type: UserActionTypes.SET_NEW_PASSWORD,
				payload: {
					cognitoUser: user,
				}
			});
			push("/setpassword")
		}
		else {
			getUserfromApiGateway()
				.then(res=>{
					localStorage.setItem('user', JSON.stringify(user))
					dispatch({
						type: UserActionTypes.SET_CURRENT_USER,
						payload: {
							cognitoUser: user,
							userData: res
						}
					});
					push("/dashboard")
				}).catch(err=>{
					dispatch({
						type: UserActionTypes.SET_LOGIN_ERROR,
						payload: {
							loginError: "Unauthorized.",
						}
					});
				})
		}
	} catch (error) {
		console.log('error signing in', error);
		dispatch({
			type: UserActionTypes.SET_LOGIN_ERROR,
			payload: {
				loginError: "Unauthorized.",
			}
		});
	}
}

const setCognitoConfirmPassword = (cognitouser, password, push) => async (dispatch) => {
	Auth.completeNewPassword(
		cognitouser, 
        password,
	)
	.then((user) => {
		// at this time the user is logged in if no MFA required
		getUserfromApiGateway()
		.then(res=>{
			localStorage.setItem('user', JSON.stringify(user))
			dispatch({
				type: UserActionTypes.SET_CURRENT_USER,
				payload: {
					cognitoUser: user,
					userData: res
				}
			});
			push("/dashboard")
		}).catch(err=>{
			dispatch({
				type: UserActionTypes.SET_LOGIN_ERROR,
				payload: {
					loginError: "Unauthorized.",
				}
			});
		})
	})
	.catch((e) => {
		console.log(e);
	});
}

const setSignout = () => async (dispatch) => {
	localStorage.removeItem('user');
	try {
		await Auth.signOut();
	} catch (error) {
		console.log('error signing out: ', error);
	}
	dispatch({
		type: UserActionTypes.SET_SIGN_OUT
	});
	dispatch({
		type: DataActionTypes.SET_LOGOUT
	})
};

export {
	setSignin,
	setSignout,
	setCurrentUser,
	flushError,
	setCognitoConfirmPassword
};
