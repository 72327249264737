import { useEffect, useState } from 'react'

import { columns } from './cols'

import {
    fetchData,
    fetchFilteredClient
} from '../../api'

import './styles.scss'

import VirtualTable from './virtual';

import { useSelector } from 'react-redux'

import EditModal from '../modals/edit'

import CsvDownload from 'react-json-to-csv'


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function DisplayAntdTable(props) {

    const { filteredData, filterActive, setFilterActive, setFilteredData } = props;

    const masterData = useSelector((state) => state.data);

    const user = useSelector((state) => state.user);

    const [loading, setLoading] = useState(true)

    const [data, setData] = useState(masterData.clients)

    const [dynamicCols, setDynamicCols] = useState(columns);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [editClient, setEditClient] = useState(false);

    useEffect(() => {
        if (masterData.clients && masterData.clients.length > 0) {
            setData(masterData.clients)
            setLoading(false);
        }
    }, [masterData.clients])

    useEffect(() => {
        setFilterActive(false);
        setFilteredData(null);
    }, [isModalVisible])

    const onEditClick = (record) => {
        setEditClient(record)
        setIsModalVisible(true)
    }

    const onViewClick = (record) => {
        console.log(record)
    }

    useEffect(() => {
        if (masterData.ranks !== null) {
            let dynamicCols = columns.slice();

            dynamicCols.splice(2, 0, {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 100,
            })

            dynamicCols[4].filters = masterData?.priorities;

            if (user.userData.admin) {
                dynamicCols[5].filters = masterData?.users;
                dynamicCols[6].filters = masterData?.users;
            } else {
                dynamicCols[5].filters = null
                dynamicCols[6].filters = null
            }

            dynamicCols[7].filters = masterData?.areas;
            dynamicCols[8].filters = masterData?.categories;
            dynamicCols[9].filters = masterData?.ranks;
            dynamicCols[10].filters = masterData?.titles;

            setDynamicCols(dynamicCols)
        }
    }, [masterData.ranks])

    const fetchClients = () => {
        if (filterActive) {
            setLoading(true)
            let filtered = []
            if (filteredData.type == "Company") {
                masterData.clients.forEach((p, k) => {
                    if (p.company === filteredData.value) {
                        filtered.push(p)
                    }
                })
            } else if (filteredData.type == "Code") {
                masterData.clients.forEach((p, k) => {
                    if (p.client_code === filteredData.value) {
                        filtered.push(p)
                    }
                })
            } else if (filteredData.type == "Person") {
                masterData.clients.forEach((p, k) => {
                    if (p.contact_person === filteredData.value) {
                        filtered.push(p)
                    }
                })
            }

            setData(filtered);
            setLoading(false);
        } else {
            setData(masterData.clients);
        }
    }

    useEffect(() => {
        fetchClients()
    }, [filterActive])

    if (!user.userData){
        window.location.reload(false);
    }
    return (
        <>
            {user.isAuthenticated &&
                <>
                    {user && user.userData.admin && <div className="download-container">
                        <CsvDownload data={data} filename="galgo_clients.csv" />
                    </div>}

                    <EditModal fetchClients={fetchClients} client={editClient} setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />
                    <VirtualTable
                        loading={loading}
                        columns={dynamicCols}
                        dataSource={data}
                        onEditClick={onEditClick}
                        onViewClick={onViewClick}
                        scroll={{
                            y: 600,
                            x: '100vw',
                        }}
                    />
                </>}
        </>
    )
}