import {Link } from "react-router-dom";
// import { Space } from "antd";

export const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: "15%"
    },
    {
        title: 'From',
        dataIndex: 'from_date',
        key: 'from_date',
        width: "15%"
    },
    {
        title: 'To',
        dataIndex: 'to_date',
        key: 'to_date',
        width: "15%"
    },
    {
        title: 'Bike Id',
        dataIndex: 'bike_id',
        key: 'bike_id',
        width: "15%",
        render: record => {
            return (
                <Link to={`/bikes/${record}`}>{record}</Link>
            )
        }
    },
    {
        title: 'Cancelled',
        dataIndex: 'cancelled',
        key: 'cancelled',
        width: "15%",
        render: record => {
            return (
                <p>{record ? 'Yes' : 'No'}</p>
            )
        }
    }
]