import { useState, useEffect } from "react";
import { Modal, Input, Form, Radio} from 'antd';

import {editUser} from '../../api'

export default function EditUser(props){

    const [username, setUsername] = useState(props.user.username)
    const [password, setPassword] = useState("")
    const [cpassword, setCpassword] = useState("")
    const [role, setRole] = useState(props.user.role)

    const [error, setError] = useState(false)


    const [form] = Form.useForm();

    useEffect(() => {
        if (props.isModalVisible === true){
            setValues();
        }
        else {
            resetValues();
        }
    }, [props.isModalVisible])

    console.log(props.user)


    const handleOk = () => {

        if (username !== "" ){

            if ((password === "" && cpassword === "") || (password !== "" && cpassword !== "" && password === cpassword)){

                editUser(props.username, String(props.user.user_id), {username, password, role})
                .then(res=>{
                    if (res.data.success){
                        resetValues();
                        props.addedUser(null)
                        props.setIsModalVisible(false);
                    }
                })
                .catch(err=>{
                    if (err.response){
                        setError(err.response.data.message)
                    } else {
                        setError("Some error occured.")
                    }
                })
            } else {
                setError("Passwords don't match.")
            }
        }
        else{
            setError("Username are required")
        }
        
    };
    
    const handleCancel = () => {
        resetValues();
        props.setIsModalVisible(false);
    };

    const handleChange = (e) => {
        setError(false)
        if (e.target.name === "username"){
            setUsername(e.target.value)
        } else if (e.target.name === "password"){
            setPassword(e.target.value)
        } else if (e.target.name === "cpassword"){
            setCpassword(e.target.value)
        } else if (e.target.name === "role"){
            setRole(e.target.value)
        } 
    }

    const resetValues = () => {
        setUsername("")
        setPassword("")
        setCpassword("")
        setRole("user")
        form.setFieldsValue({
            username: "",
            password: "",
            cpassword: "",
            role: "user"
        })
    }

    const setValues = () => {
        setUsername(props.user.username)
        setPassword("")
        setCpassword("")
        setRole(props.user.role)
        form.setFieldsValue({
            username: props.user.username,
            password: "",
            cpassword: "",
            role: props.user.role
        })
    }

    return (
        <Modal title="Add User" visible={props.isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    name="basic"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    autoComplete="off"
                    form={form}
                    >
                    <Form.Item
                        label="Username"
                        name="username"
                        required={true}
                    >
                        <Input name="username" value={username} onChange={handleChange}/>
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        required={true}
                    >
                        <Input type="password" name="password" value={password} onChange={handleChange}/>
                    </Form.Item>
                    <Form.Item
                        label="Confirm Password"
                        name="cpassword"
                        required={true}
                    >
                        <Input type="password" name="cpassword" value={cpassword} onChange={handleChange}/>
                    </Form.Item>
                    <Form.Item name="role" label="Role" required={true}>
                        <Radio.Group name="role" onChange={handleChange} value={role}>
                        <Radio value="user">User</Radio>
                        <Radio value="manager">Manager</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
                {error && <p style={{
                    color: "red"
                }}>{error}</p>}
        </Modal>
    )

}