import { BrowserRouter as Router, Switch, useHistory } from 'react-router-dom'

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

import Login from '../components/login';
import Dashboard from '../components/dashboard';
import Users from '../components/users';
import User from '../components/user'
import SetPassword from '../components/setpassword'
// import Signup from '../components/signup';
// import DataTable from '../components/table'

function AppRoutes(props) {

	const isLogedIn = () => {
		return Boolean(localStorage.getItem('user'));
	};

    const {isAuthenticated, isSetPassword} = props;

    return (
        <Router>
            <Switch>
              <PublicRoute
					restricted={isAuthenticated || isLogedIn()}
					redirect={'/dashboard'}
					exact
					path={['/', '/login']}
					component={Login}
				/>
				<PublicRoute
					restricted={isAuthenticated || isLogedIn()}
					redirect={'/dashboard'}
					exact
					path={['/setpassword']}
					component={SetPassword}
				/>
				<PrivateRoute
					isAuthenticated={isAuthenticated || isLogedIn()}
					exact
					path='/users'
					component={Users}
				/>
                <PrivateRoute
					isAuthenticated={isAuthenticated || isLogedIn()}
					exact
					path={['/','/dashboard']}
					component={Dashboard}
				/>
				<PrivateRoute
					isAuthenticated={isAuthenticated || isLogedIn()}
					exact
					path='/users/:username'
					component={User}
				/>
				{/* <PrivateRoute
					isAuthenticated={isAuthenticated || isLogedIn()}
					exact
					path='/table'
					component={DataTable}
				/> */}
            </Switch>
        </Router>
    )
}

export default AppRoutes
