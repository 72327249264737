import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Table, Space, Button} from 'antd';

import { columns } from './cols';

import AddUser from './addUser'
import EditUser from './editUser';

import {
  fetchAllUsers
} from '../../api'

import './styles.css'

import {useSelector} from 'react-redux'

function Users() {

  const user = useSelector((state) => state.user);
  
  const [users, setUsers] = useState(null);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  const [dynamicols, setDynamicols] = useState(columns);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [editUser, setEditUser] = useState(false);

  const [editModalVisible, setEditModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(()=>{
    if (users === null && user !== null){
      setLoading(true);
      fetchAllUsers(user.username)
      .then(res=>{
        setUsers(res.data.data)

        const dynamicCols = columns.slice();

        dynamicCols[4] = {
          title: 'Action',
          key: 'action',
          align: 'center',
          width: 50,
          render: (record) => {
              return (
                <Space size="middle">
                  <Link to={`/users/${record.username}`}>View</Link>
                  <Button onClick={()=>onEditClick(record)}>Edit</Button>
                </Space>
              )
          }
        }

        setDynamicols(dynamicCols)

        setLoading(false);
      })
      .catch(err=>{
        console.log(err)
        setError(true);
        setLoading(false);
      })
    }
  }, [users, user])

  const onEditClick = (record) => {
    setEditUser(record);
    setEditModalVisible(true);
  }

  return (
    <div className="dashboard-container">
        <div className="top-wrapper">
          {user && user.role !== "user" && (
            <div className="create-button" onClick={showModal}>
              Add User
            </div>
          )}
          { user && user.role !== "user" && <AddUser addedUser={setUsers} setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} username={user.username}/>}
          { user && user.role !== "user" && <EditUser user={editUser} addedUser={setUsers} setIsModalVisible={setEditModalVisible} isModalVisible={editModalVisible} username={user.username}/>}
        </div>
        {/* <Button className='login-button' type='primary' onClick={logoutClick}>Logout</Button> */}
        {!error && user &&
        <Table 
          columns={dynamicols} 
          dataSource={users} 
          rowKey="user_id"
          loading={loading}
          scroll={{ y: 500 }}
        />}
        {error && <div className='dashboard-error'>ERROR BRUH</div>}
    </div>
  );
}

export default Users;
