import { Modal } from 'antd';

import {withRouter} from 'react-router-dom';

import {
  deleteUsers
} from '../../api';

function DeleteUser(props){

    const { history } = props;

    const handleOk = () => {
        props.setVisible(false);
        deleteUsers(props.username, props.user.username)
        .then(res=>{
          props.setVisible(false);
          history.push('/users');
        })
        .catch(err=>{
          console.log(err);
          props.setVisible(false);
        })
    };

    const handleCancel = () => {
        props.setVisible(false);
    };

    return (
        <>
          <Modal title="Delete User" 
          visible={props.visible} 
          onOk={handleOk} 
          onCancel={handleCancel}
          cancelText="No"
          okText="Yes">
            <p>This will delete all bikes, reservations and ratings associated with this user.</p>
            <p>Are you sure you want to continue ?</p>
          </Modal>
        </>
      );
}

export default withRouter(DeleteUser);