import { combineReducers } from 'redux';
import userReducer from './user/user.reducer';
import dataReducer from './data/data.reducer'

const rootReducer = combineReducers({
	user: userReducer,
	data: dataReducer
});

export default rootReducer;
