import { useState, useEffect, useRef } from "react";
import { Modal, Input, Form, Radio, Select, AutoComplete} from 'antd';

import { useSelector, useDispatch } from "react-redux";

import {
    setClientData,
    fetchMasterData,
    fetchValues
} from '../../redux/data/data.actions'

import {
    editData
} from '../../api'

export default function EditModal(props){

    const { Option } = AutoComplete;

    const {client} = props;

    const dispatch = useDispatch();

    const messagesEndRef = useRef(null)

    const [error, setError] = useState(false)

    const [form] = Form.useForm();

    const masterData = useSelector((state) => state.data);

    const user = useSelector((state) => state.user);

    const [clientId, setClientId] = useState("");
    const [company, setCompany] = useState("");
    const [priority, setPriority] = useState("")
    const [area, setArea] = useState("");
    const [currentHandler, setCurrentHandler] = useState("");
    const [category, setCategory] = useState("")
    const [rank, setRank] = useState("");
    const [title, setTitle] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [designation, setDesignation] = useState("");
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [cityPincode, setCityPincode] = useState("");
    const [telephone, setTelephone] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [mobile2, setMobile2] = useState("");
    const [extras, setExtras] = useState("");
    const [noMeeting, setNomeeting] = useState("");
    const [telephonically, setTelephonically] = useState("");

    const [companyOptions, setCompanyOptions] = useState([]);


    useEffect(() => {
        setValues();
    }, [props.isModalVisible])

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const handleOk = () => {

            let data = {
            "client_id": clientId,
            "company":company,
            "priority":priority,
            "handler":currentHandler,
            "area":area,
            "category":category,
            "rank":rank,
            "title":title,
            "person":contactPerson,
            "designation":designation,
            "address":address,
            "address2":address2,
            "pin":cityPincode,
            "tel":telephone,
            "mobile":mobile,
            "email":email,
            "mobile2":mobile2,
            "extras":extras,
            noMeeting,
            telephonically
        }

        editData(data)
        .then(res=>{
            console.log(res)
            if (res.data.success){

                updateLocalMaster(res.data.data);

                props.setIsModalVisible(false);
                dispatch(fetchMasterData());
                dispatch(fetchValues());
                resetValues();
                
            }
        })
        .catch(err=>console.log(err))
    };

    const updateLocalMaster = (record) => {
        let clonedArray = JSON.parse(JSON.stringify(masterData.clients))
        clonedArray.forEach((c,k) => {
            if (c.client_id === record.client_id){
                clonedArray[k] = record
            }
        })
        dispatch(setClientData(clonedArray))
    }
    
    const handleCancel = () => {
        resetValues();
        props.setIsModalVisible(false);
    };

    const handleChange = (e) => {
        setError(false)
        if (e.target.name === "contactPerson"){
            setContactPerson(e.target.value);
        }else if (e.target.name === "designation"){
            setDesignation(e.target.value);
        } else if (e.target.name === "address"){
            setAddress(e.target.value);
        } else if (e.target.name === "address2"){
            setAddress2(e.target.value);
        }else if (e.target.name === "cityPincode"){
            setCityPincode(e.target.value);
        } else if (e.target.name === "telephone"){
            setTelephone(e.target.value);
        } else if (e.target.name === "mobile2"){
            setMobile2(e.target.value);
        } else if (e.target.name === "mobile"){
            setMobile(e.target.value);
        } else if (e.target.name === "email"){
            setEmail(e.target.value);
        } else if (e.target.name === "extras"){
            setExtras(e.target.value);
        }
    }

    const handlePriorityChange = (value) => {
        setPriority(value);
    }

    const handleHandlerChange = (value) => {
        setCurrentHandler(value);
    }

    const handleAreaChange = (value) => {
        setArea(value);
    }

    const hanldeCategoriesChange = (value) => {
        setCategory(value);
    }

    const handleTitleChange = (value) => {
        setTitle(value);
    }

    const handleRankChange = (value) => {
        setRank(value);
    }


    const setValues = () => {
        setClientId(props.client.client_id);
        setCompany(props.client.company);
        setPriority(props.client.priority)
        setCurrentHandler(props.client.current_handler);
        setArea(props.client.area);
        setCategory(props.client.category)
        setRank(props.client.rank);
        setTitle(props.client.title);
        setContactPerson(props.client.contact_person);
        setDesignation(props.client.designation);
        setAddress(props.client.address);
        setAddress2(props.client.address2);
        setCityPincode(props.client.city_pincode);
        setTelephone(props.client.tel);
        setMobile(props.client.mobile);
        setEmail(props.client.email);
        setMobile2(props.client.mobile2);
        setExtras(props.client.extras);
        setNomeeting(props.client.no_meeting);
        setTelephonically(props.client.telephonically);
        form.setFieldsValue({
            clientId: props.client.client_id,
            company: props.client.company,
            priority: props.client.priority,
            currentHandler: props.client.current_handler,
            area:props.client.area,
            category: props.client.category,
            rank: props.client.rank,
            title: props.client.title,
            contactPerson: props.client.contact_person,
            designation: props.client.designation,
            address: props.client.address,
            address2: props.client.address2,
            cityPincode: props.client.city_pincode,
            telephone: props.client.tel,
            mobile: props.client.mobile,
            email: props.client.email,
            mobile2: props.client.mobile2,
            extras: props.client.extras,
            noMeeting: props.client.no_meeting,
            telephonically: props.client.telephonically,
        })
    }

    const resetValues = () => {
        setClientId("");
        setCompany("");
        setPriority("")
        setCurrentHandler("");
        setCategory("");
        setArea("");
        setRank("");
        setTitle("");
        setContactPerson("");
        setDesignation("");
        setAddress("");
        setAddress2("");
        setCityPincode("");
        setTelephone(""); 
        setMobile("");
        setEmail("");
        setMobile2("");
        setExtras("");
        setNomeeting(false);
        setTelephonically(false);
        form.setFieldsValue({
            clientId: "",
            company: "",
            priority: "",
            currentHandler: "",
            category: "",
            area: "",
            rank: "",          
            title: "",
            contactPerson: "",
            designation: "",
            address: "",
            address2: "",
            cityPincode: "",
            telephone: "",
            mobile: "",
            email: "",
            mobile2: "",
            extras: "",
            noMeeting: false,
            telephonically: false,
        })
    }

    const handleSearch = (v) => {
        if ( v !== ""){
            let filters = []
            // console.log(masterData.companies);
            masterData.ac_companies.forEach((c,k) => {
                if (v !== "" && c.company_name.toLowerCase().indexOf(v.toLowerCase()) >= 0){
                    filters.push({
                        "key": k,
                        "value": c.company_name,
                        "code": c.client_code
                    })
                }
                if (v !== "" && c.client_code.toLowerCase().indexOf(v.toLowerCase()) >= 0){
                    filters.push({
                        "key": k,
                        "value": c.company_name,
                        "code": c.client_code
                    })
                }
            })
            // filters.push({
            //     "key": 1000000,
            //     "value": v,
            //     "type": "Company",
            //     "display": v
            // })
            setCompanyOptions(filters)  
        }
    }

    const onSelect = (value) => {
        setCompany(value)
    }

    return user && (
        <Modal 
            width={"70%"} 
            title="Add Client" 
            visible={props.isModalVisible} 
            onOk={handleOk} 
            onCancel={handleCancel}
            keyboard={true}
            bodyStyle={{
                overflow: "auto",
                height: "500px"
            }}
            getContainer={false}>
                <Form
                    name="basic"
                    labelCol={{ span: 9 }}
                    wrapperCol={{ span: 10 }}
                    autoComplete="off"
                    form={form}
                    >
                    <Form.Item label="Company or Client Code" name="company" required={true}>   
                        <AutoComplete 
                        style={{ width: 300 }} 
                        onSearch={handleSearch} 
                        placeholder="Input here"
                        value={company}
                        onChange={setCompany}
                        onSelect={onSelect}
                        allowClear={true}>
                            {companyOptions.map((f,k) => (
                                <Option 
                                key={f.key} 
                                value={f.value}
                                code={f.code}
                                // style={{
                                //     background: f.type === "Company" ? '#F48FB1' : '#CE93D8'
                                // }}
                                >
                                    {f.code} - {f.value}
                                </Option>
                            ))}
                        </AutoComplete>
                    </Form.Item>
                    <Form.Item label="Priority" name="priority" required={true}>   
                        <Select onChange={handlePriorityChange} name="priority">
                        {masterData?.priorities?.map((p,k)=>(
                            <Select.Option key={k} value={p.text}>{p.text}</Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    {user?.userData?.admin && (<Form.Item label="Current Handler" name="currentHandler" required={true}>   
                        <Select onChange={handleHandlerChange}>
                        {masterData?.users?.map((u,k)=>(
                            <Select.Option key={k} value={u.user_id}>{u.value}</Select.Option>
                        ))}
                        </Select>
                    </Form.Item>)}
                    <Form.Item label="Area" name="area" required={true}>   
                        <Select onChange={handleAreaChange}>
                        {masterData?.areas?.map((a,k)=>(
                            <Select.Option key={k} value={a.text}>{a.text}</Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Category" name="category" required={true}>   
                        <Select onChange={hanldeCategoriesChange}>
                        {masterData?.categories?.map((c,k)=>(
                            <Select.Option key={k} value={c.text}>{c.text}</Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Rank" name="rank" required={true}>   
                        <Select onChange={handleRankChange}>
                        {masterData?.ranks?.map((r,k)=>(
                            <Select.Option key={k} value={r.text}>{r.text}</Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Title" name="title" required={true}>   
                        <Select onChange={handleTitleChange}>
                        {masterData?.titles?.map((t,k)=>(
                            <Select.Option key={k} value={t.text}>{t.text}</Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Contact Person" name="contactPerson" required={true}>   
                        <Input name="contactPerson" onChange={handleChange} maxLength={100} value={contactPerson}></Input>
                    </Form.Item>
                    <Form.Item label="Designation" name="designation" required={true}>   
                        <Input maxLength={50} name="designation" onChange={handleChange} value={designation}></Input>
                    </Form.Item>
                    <Form.Item label="Address" name="address">   
                        <Input.TextArea maxLength={200} name="address" onChange={handleChange} value={address}></Input.TextArea>
                    </Form.Item>
                    <Form.Item label="Address 2" name="address2">   
                        <Input.TextArea maxLength={200} name="address2" onChange={handleChange} value={address2}></Input.TextArea>
                    </Form.Item>
                    <Form.Item label="City Pincode" name="cityPincode">   
                        <Input maxLength={15} name="cityPincode" onChange={handleChange} value={cityPincode}></Input>
                    </Form.Item>
                    <Form.Item label="Telephone" name="telephone">   
                        <Input maxLength={50} name="telephone" onChange={handleChange} value={telephone}></Input>
                    </Form.Item>
                    <Form.Item label="Mobile" name="mobile">   
                        <Input maxLength={50} name="mobile" onChange={handleChange} value={mobile}></Input>
                    </Form.Item>
                    <Form.Item label="Email" name="email">   
                        <Input maxLength={200} name="email" onChange={handleChange} value={email}></Input>
                    </Form.Item>
                    <Form.Item label="Mobile 2" name="mobile2">   
                        <Input maxLength={50} name="mobile2" onChange={handleChange} value={mobile2}></Input>
                    </Form.Item>
                    <Form.Item label="Extras" name="extras">   
                        <Input.TextArea maxLength={200} name="extras" onChange={handleChange} value={extras}></Input.TextArea>
                    </Form.Item>
                </Form>
                {error && <div className="error-container">{error}</div>}
                <div ref={messagesEndRef} />
        </Modal>
    )
}