import { UserActionTypes } from './user.types';

const INITIAL_STATE = {
	userData: null,
	isAuthenticated: false,
	loginError: "",
	newPasswordRequired: false,
	cognitoUser: null
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		case UserActionTypes.SET_LOGIN_ERROR: 
			return {
				...state,
				isAuthenticated: false,
				loginError: action.payload.loginError
			};
			
		case UserActionTypes.SET_CURRENT_USER:
			return {
				...state,
				userData: action.payload.userData,
				cognitoUser: action.payload.cognitoUser,
				isAuthenticated: true,
				newPasswordRequired: false
			};

		case UserActionTypes.SET_SIGN_OUT:
			return {
				...state,
				userData: null,
				isAuthenticated: false
			};
		
		case UserActionTypes.SET_NEW_PASSWORD: 
			return {
				...state,
				newPasswordRequired: true,
				cognitoUser: action.payload.cognitoUser
			}
		
		default:
			return state;
	}
};

export default userReducer;
