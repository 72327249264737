import 'antd/dist/antd.css';

import AppRoutes from './router'

import {useSelector} from 'react-redux'

import {useEffect} from 'react';

import { Amplify } from 'aws-amplify';

import {
  setCurrentUser
} from './redux/user/user.actions'

import {
  useDispatch
} from 'react-redux'

import {AWS_USER_POOL_ID, AWS_REGION, AWS_COGNITO_CLIENT_ID} from './env.json';


Amplify.configure({
  Auth: {
    region: AWS_REGION,
    userPoolId: AWS_USER_POOL_ID,
    userPoolWebClientId: AWS_COGNITO_CLIENT_ID
  }
});

function App() {
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentUser());
  }, [dispatch])

  const userRedux = useSelector((state) => state.user);

  return (
    <div className="App">
      <AppRoutes isAuthenticated={userRedux.isAuthenticated} isSetPassword={userRedux.newPasswordRequired}/>
    </div>
  );
}

export default App;
