import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Navbar from "../components/navbar";

const propTypes = {
	isAuthenticated: PropTypes.bool,
	component: PropTypes.func.isRequired,
	redirect: PropTypes.string,
	restricted: PropTypes.bool,
};

const defaultProps = {
	restricted: false,
	redirect: "/login",
	isAuthenticated: false,
};

const PrivateRoute = ({
	isAuthenticated,
	component: Component,
	redirect: pathname,
	restricted,
	...rest
}) => {

	return (
		<Route
			{...rest}
			render={(props) =>
				{
				return (isAuthenticated && !restricted ? (
					(
					<>
						<Navbar/>
						<Component {...props} />
					</>
					)
				) : (
					<Redirect to={{ pathname }} />
				))
			}
			}
		/>
	);
};

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;

export default withRouter(PrivateRoute);