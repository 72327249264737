import { DataActionTypes } from './data.types';

import {
	fetchAutoCompleteData,
    fetchFilterValues,
	fetchData
} from '../../api'

const fetchValues = () => async (dispatch) => {
	await fetchAutoCompleteData()
	.then(res=>{
		dispatch({
			type: DataActionTypes.SET_AUTOCOMPLETE_DATA,
			payload: {
				ac_companies: res.data.companies,
				contact_persons: res.data.contact_persons
			}
		});
	}).catch(err=>{
		console.log(err)
	})
	await fetchFilterValues()
	.then(res=>{
		dispatch({
			type: DataActionTypes.SET_MASTER_DATA,
			payload: {
				ranks: res.data.ranks,
				priorities: res.data.priorities,
				categories: res.data.categories,
				titles: res.data.titles,
				users: res.data.users,
				areas: res.data.areas,
				companies: res.data.companies
			}
		});
	}).catch(err=>{
		console.log(err)
	})
}

const fetchMasterData = () => async (dispatch) => {
	fetchData()
	.then(res=>{
		dispatch({
			type: DataActionTypes.SET_CLIENT_DATA,
			payload: {
				clients: res.data.clients
			}
		});
	})
	.catch(err=>{
		console.log(err)
	})
}

const setClientData = (clientData) => async (dispatch) => {
	dispatch({
		type: DataActionTypes.SET_CLIENT_DATA,
		payload: {
			clients: clientData
		}
	});
}

export {
	fetchValues,
	fetchMasterData,
	setClientData
};
