import axios from 'axios';

import {Auth} from 'aws-amplify'

const awsinstance = axios.create();

// Add a request interceptor
awsinstance.interceptors.request.use(
	async (config) => {
		const idTokenRegex = /CognitoIdentityServiceProvider\S+idToken/;
		const token = localStorage.getItem(
			Object.keys(localStorage).find((item) => item.match(idTokenRegex))
		);
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

const refreshAccessToken = async () => {
	return await Auth.currentSession();
};

const signout = async () => {
	localStorage.removeItem('user');
		try {
			await Auth.signOut();
		} catch (error) {
			console.log('error signing out: ', error);
		}
};

// Response interceptor for API calls
awsinstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		console.log(originalRequest, error)

		// if (error.response.data.message === "Token Expired"){
		// 	signout();
		// 	window.location.reload();
		// }

		if (error?.response?.status === 401 && !originalRequest?._retry) {
			originalRequest._retry = true;

			try {
				const access_token = await refreshAccessToken();
				awsinstance.defaults.headers.common['Authorization'] =
					'Bearer ' + access_token.getIdToken().getJwtToken();
				return awsinstance(originalRequest);
			} catch (error) {
				if (error?.code === 'NotAuthorizedException') {
					signout();
				}
			}
		}
		return Promise.reject(error);
	}
);

export default awsinstance;
