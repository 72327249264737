export const columns = [
    {
        title: 'User ID',
        dataIndex: 'user_id',
        key: 'user_id',
        align: 'center',
        width: '20%'
    },
    {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        align: 'center',
        width: '20%'
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        align: 'center',
        width: '20%'
    }
]