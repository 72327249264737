import { useState } from 'react';
import { Input } from 'antd';
import { Button } from 'antd';

import {withRouter, useHistory} from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux';

import {
  setSignin,
  flushError
} from '../../redux/user/user.actions'

import './login.css'
import { useEffect } from 'react';

function Login(props) {

  const dispatch = useDispatch();

  const userRedux = useSelector((state) => state.user);

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

    const history = useHistory();
    const { push } = history;

    const onFieldsChange = (e) => {
      if (e.target.name === "username"){
        setUsername(e.target.value);
        dispatch(flushError())
      } else if (e.target.name === "password"){
        setPassword(e.target.value);
        dispatch(flushError())
      }
    }

    const onLoginClick = (e) => {
      dispatch(setSignin(username, password, push))
    }

    const handleKeypress = e => {
      //it triggers by pressing the enter key
    if (e.key === "Enter") {
      onLoginClick();
    }
  };

    return (
      <div className="login-container">
          <div className="login-navbar">
            Galgo Fine Papers
          </div>
          <h1>LOGIN</h1>
          <Input onKeyPress={handleKeypress} className="login-input" name='username' placeholder='johndoe123' value={username} onChange={onFieldsChange}/>
          <Input onKeyPress={handleKeypress} className="login-input" name='password' type='password' placeholder='password' value={password} onChange={onFieldsChange}/>
          <Button className='login-button' onClick={onLoginClick} type="primary">Login</Button>
          {userRedux.loginError.length > 0 && <p style={{color: 'red'}}>{userRedux.loginError}</p>}
      </div>
    );
  }
  
  export default withRouter(Login);
  