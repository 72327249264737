import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';
import { VariableSizeGrid as Grid } from 'react-window';
import React, { useState, useEffect, useRef } from 'react';
import { Table, Space, Button } from 'antd';

import { useDispatch, useSelector } from 'react-redux'

import { Menu, Dropdown, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';


export default function VirtualTable(props) {


  const dispatch = useDispatch();

  const masterData = useSelector((state) => state.data);

  const user = useSelector((state) => state.user);

  const { Column } = Table

  const { columns, scroll, loading, onEditClick, onViewClick } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const widthColumnCount = columns.filter(({ width }) => !width).length;

  const [mergedColumns, setMergedColumns] = useState([])

  useEffect((
  ) => {
    const mergedColumnstemp = columns.map((column) => {
      if (column.width) {
        return column;
      }
  
      return { ...column, width: Math.floor(tableWidth / widthColumnCount) };
    });
    setMergedColumns(mergedColumnstemp)
  }, [columns, tableWidth, widthColumnCount])

  const gridRef = useRef();
  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: (scrollLeft) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft,
          });
        }
      },
    });
    return obj;
  });

  const resetVirtualGrid = () => {
    gridRef?.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);

  const onCellMouseEnter = (rowIndex) => {
    const cells = document.getElementsByClassName(`cell--hover${rowIndex}`);
    if (cells.length > 0) {
      for (const cell of cells) { cell.classList.add('tablegrid__cellhovered'); }
    }
  };

  const onCellMouseOut = (rowIndex) => {
    const cells = document.getElementsByClassName(`cell--hover${rowIndex}`);
    if (cells.length > 0) {
      for (const cell of cells) { cell.classList.remove('tablegrid__cellhovered'); }
    }
  };

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    ref.current = connectObject;
    const totalHeight = rawData.length * 100;
    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={mergedColumns.length}
        columnWidth={(index) => {
          const { width } = mergedColumns[index];
          return totalHeight > scroll.y && index === mergedColumns.length - 1
            ? width - scrollbarSize - 1
            : width;
        }}
        height={scroll.y}
        rowCount={rawData.length}
        rowHeight={() => 100}
        width={tableWidth}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft,
          });
        }}
      >
        {({ columnIndex, rowIndex, style }) => {
          let styles = {
            ...style,
            background: rowIndex % 2 === 0 ? '#A5D6A7' : '#FFE082',
            fontSize: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '0.25px solid black'
          }
          if (columnIndex === 0) {
            return (<div
              className={classNames('virtual-table-cell', {
                'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
              }, `cell--hover${rowIndex}`)}
              style={styles}
              onMouseEnter={() => onCellMouseEnter(rowIndex)}
              onMouseOut={() => onCellMouseOut(rowIndex)}
            >
              {rowIndex + 1}
            </div>)
          }
          if (columnIndex === 2) {
            console.log(styles)
            styles.justifyContent = 'space-evenly'
            styles.width = 100
            console.log("ACTION BUTTON", styles)
            return (<div
              className={classNames('virtual-table-cell', {
                'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
              }, `cell--hover${rowIndex}`)}
              style={styles}
              onMouseEnter={() => onCellMouseEnter(rowIndex)}
              onMouseOut={() => onCellMouseOut(rowIndex)}
            >
              {/* <div className="action-button" onClick={()=>onEditClick(rawData[rowIndex])}>View</div> */}
              <div className="action-button" onClick={() => onEditClick(rawData[rowIndex])}>Edit</div>
            </div>)
          }
          // else if (columnIndex === 2 && user.userData.admin){
          //         styles.padding = 0;
          //       let m = rawData[rowIndex][mergedColumns[columnIndex].dataIndex]
          //       if (m === true){
          //         m = "Yes"
          //       } else if (m === false){
          //         m = "No"
          //       }
          //       return (<div
          //         className={classNames('virtual-table-cell', {
          //           'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
          //         }, `cell--hover${rowIndex}`)}
          //         style={styles}
          //         onMouseEnter={() => onCellMouseEnter(rowIndex)}
          //         onMouseOut={() => onCellMouseOut(rowIndex)}
          //       >
          //           <select value={rawData[rowIndex][mergedColumns[columnIndex].dataIndex]} onChange={(e)=>console.log(e.target.value, rawData[rowIndex].client_id)}>
          //             {masterData?.users?.map((u,k)=>{
          //               return (
          //                 <option key={k} value={u.value}>{u.value}</option>
          //               )
          //             })}
          //           </select>
          //       </div>)
          // }
          else {
            if (columnIndex > 2){
              if (columnIndex === 3){
                styles.width = 300
                styles.left = styles.left - 200
              } else if (columnIndex === 4){
                styles.width = styles.width + 115
                styles.left = styles.left - 130
              } else {
                styles.left = styles.left - 15
              }
            }
            let m = rawData[rowIndex][mergedColumns[columnIndex].dataIndex]
            if (m === true) {
              m = "Yes"
            } else if (m === false) {
              m = "No"
            }
            return (<div
              className={classNames('virtual-table-cell', {
                'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
              }, `cell--hover${rowIndex}`)}
              style={styles}
              onMouseEnter={() => onCellMouseEnter(rowIndex)}
              onMouseOut={() => onCellMouseOut(rowIndex)}
            >
              {m}
            </div>)
          }

        }}
      </Grid>
    );
  };

  return (
    (<ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <Table
        {...props}
        loading={loading}
        //   className="virtual-table"
        className="bcal-table"
        columns={mergedColumns}
        pagination={false}
        components={{
          body: renderVirtualList,
        }}
      />
    </ResizeObserver>)
  );
} // Usage