import './styles.css'

import { useDispatch, useSelector } from 'react-redux'

import DisplayAntdTable from '../antd_table';

import {
    fetchValues,
    fetchMasterData
} from '../../redux/data/data.actions'
import { useEffect, useState } from 'react';

import { AutoComplete } from 'antd';

import AddModal from '../modals/add';

function Dashboard() {

    const dispatch = useDispatch();

    const { Option } = AutoComplete;

    const masterData = useSelector((state) => state.data);

    const userRedux = useSelector((state) => state.user.userData);

    useEffect(() => {
        dispatch(fetchMasterData())
        dispatch(fetchValues())
    }, [])

    const [filteredOptions, setFilteredOptions] = useState([])
    const [value, setValue] = useState(null)

    const [filterActive, setFilterActive] = useState(false);
    const [filteredData, setFilteredData] = useState(null);

    const [isAddModalVisible, setIsAddModalVisible] = useState(false);



    useEffect(() => {
        if (value === undefined || value === null || value === "") {
            setFilterActive(false);
            setFilteredData(null);
        }
    }, [value])

    const handleSearch = (v) => {
        let filters = []
        masterData.contact_persons.forEach((c, k) => {
            if (v !== "" && c.contact_person.toLowerCase().indexOf(v.toLowerCase()) >= 0) {
                filters.push({
                    "key": `p_${k}`,
                    "value": c.contact_person,
                    "type": "Person",
                    "code": "p",
                    "background": "#C5CAE9"
                })
            }
        })
        masterData.ac_companies.forEach((p, k) => {
            if (v !== "" && p.company_name.toLowerCase().indexOf(v.toLowerCase()) >= 0) {
                filters.push({
                    "key": `cn_${k}`,
                    "value": p.company_name,
                    "type": "Company",
                    "code": "cn",
                    "background": '#F48FB1'
                })
            }
            if (v !== "" && p.client_code.toLowerCase().indexOf(v.toLowerCase()) >= 0) {
                filters.push({
                    "key": `cc_${k}`,
                    "value": p.client_code,
                    "type": "Code",
                    "code": "cc",
                    "background": '#CE93D8'
                })
            }
        })
        setFilteredOptions(filters)
    }

    const onSelect = (value, option) => {
        setFilterActive(true);
        setFilteredData(option);
    }
    if (userRedux){
        return (
            <div className="dashboard-container">
                <AddModal setIsModalVisible={setIsAddModalVisible} isModalVisible={isAddModalVisible} />
                <div className="dashboard-input-container">
                    <p className="dashboard-search-helper-text">Search using Company or Contact Person :</p>
                    <AutoComplete
                        style={{ width: 300 }}
                        onSearch={handleSearch}
                        placeholder="input here"
                        value={value}
                        onChange={setValue}
                        onSelect={onSelect}
                        allowClear={true}>
                        {filteredOptions.map((f, k) => (
                            <Option
                                key={f.key}
                                value={f.value}
                                type={f.type}
                                code={f.code}
                                style={{
                                    background: f.background
                                }}>
                                {f.type} : {f.value}
                            </Option>
                        ))}
                    </AutoComplete>
                    <div className="add-client-button" onClick={() => setIsAddModalVisible(true)}>Add Client</div>
                </div>
                <div className="dashboard-table-container">
                    <DisplayAntdTable
                        filteredData={filteredData}
                        filterActive={filterActive}
                        setFilterActive={setFilterActive}
                        setFilteredData={setFilteredData}
                    />
                </div>
            </div>
        );
    }else {
        return (
            <></>
        )
    }
}

export default Dashboard;
