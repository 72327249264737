import axios from 'axios';

const instance = axios.create();

// Add a request interceptor
instance.interceptors.request.use(
	async (config) => {
		try {
			const user = localStorage.getItem('user');
			if (user) {
				config.headers['Authorization'] = 'Bearer ' + JSON.parse(user).token;
			}
		} catch (error) {
			console.log("ERRUR", error)
			if (error?.code === 'NotAuthorizedException') {
				signout();
				return;
			}
		}

		// const idTokenRegex = /CognitoIdentityServiceProvider\S+idToken/;
		// const token = localStorage.getItem(
		// 	Object.keys(localStorage).find((item) => item.match(idTokenRegex))
		// );
		// if (token) {
		// 	config.headers['Authorization'] = 'Bearer ' + token;
		// }
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

// const refreshAccessToken = async () => {
// 	return await Auth.currentSession();
// };

const signout = () => {
	localStorage.removeItem('user');
};

// Response interceptor for API calls
instance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		console.log(originalRequest, error.response.data.mes)

		if (error.response.data.message === "Token Expired"){
			signout();
			window.location.reload();
		}

		// if (error?.response?.status === 401 && !originalRequest?._retry) {
		// 	originalRequest._retry = true;

		// 	try {
		// 		const access_token = await refreshAccessToken();
		// 		instance.defaults.headers.common['Authorization'] =
		// 			'Bearer ' + access_token.getIdToken().getJwtToken();
		// 		return instance(originalRequest);
		// 	} catch (error) {
		// 		if (error?.code === 'NotAuthorizedException') {
		// 			signout();
		// 		}
		// 	}
		// }
		return Promise.reject(error);
	}
);

export default instance;
