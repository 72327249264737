import { withRouter } from "react-router";

import './styles.css'

import {
    setSignout
} from '../../redux/user/user.actions'

import {useSelector, useDispatch} from 'react-redux'

function Navbar(props){

    const { history } = props;

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);


    const logoutClick = (e) => {
        dispatch(setSignout());
      }

    return (
        <div className="navbar-container">
            <div className="left-links">
                <div className="navbar-link" onClick={()=>history.push("/dashboard")}>
                    Hello, {user?.userData?.full_name} | {new Date().toUTCString().slice(5, 16)}
                </div>
                {/* {user && user.role !== "user" && 
                <div className="navbar-link" onClick={()=>history.push("/users")}>
                    Users
                </div>} */}
            </div>
            <div onClick={()=>history.push("/dashboard")} className="header">Galgo Fine Papers</div>
            <div className="right-links">
                {/* <div className="navbar-link" onClick={()=>history.push("/reservations")}>
                    My Reservations
                </div> */}
                <div className="navbar-link" onClick={logoutClick}>
                    Logout
                </div>
            </div>
        </div>
    )

}

export default withRouter(Navbar);