import { useState } from 'react';
import { Input } from 'antd';
import { Button } from 'antd';

import {useHistory, withRouter} from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux';

import {
  setCognitoConfirmPassword,
  flushError
} from '../../redux/user/user.actions'

import './setpassword.css'

function SetPassword(props) {

  const dispatch = useDispatch();

  const userRedux = useSelector((state) => state.user);

  const [password, setPassword] = useState("")
  const [confirmpassword, setConfirmPassword] = useState("")
  const [buttondisabled, setButtonDisabled] = useState(true)


  const history = useHistory();
  if (!userRedux.cognitoUser){
    history.push("/login")
  }

    const onFieldsChange = (e) => {
      if (e.target.name === "confirmpassword"){
        setConfirmPassword(e.target.value);
        dispatch(flushError())
        if (e.target.value === password){
            setButtonDisabled(false)
        }
        else{
            setButtonDisabled(true)
        }
      } else if (e.target.name === "password"){
        setPassword(e.target.value);
        dispatch(flushError())
        if (e.target.value === confirmpassword){
            setButtonDisabled(false)
        }
        else{
            setButtonDisabled(true)
        }
      }
    }

    const setPasswordClick = (e) => {
        dispatch(setCognitoConfirmPassword(userRedux.cognitoUser, password))
    }

    const handleKeypress = e => {
      //it triggers by pressing the enter key
    if (e.key === "Enter") {
        setPasswordClick();
    }
  };

    return (
      <div className="login-container">
          <div className="login-navbar">
            Galgo Fine Papers
          </div>
          <h1>Set New Password</h1>
          <Input onKeyPress={handleKeypress} className="login-input" name='password' type='password' placeholder='password' value={password} onChange={onFieldsChange}/>
          <Input onKeyPress={handleKeypress} className="login-input" name='confirmpassword' type='password' placeholder='password' value={confirmpassword} onChange={onFieldsChange}/>
          <Button disabled={buttondisabled} className='login-button' onClick={setPasswordClick} type="primary">Set Password</Button>
          {userRedux.loginError.length > 0 && <p style={{color: 'red'}}>{userRedux.loginError}</p>}
      </div>
    );
  }
  
  export default withRouter(SetPassword);
  