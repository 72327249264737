import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer from './root-reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares = [ReduxThunk];

if (process.env.NODE_ENV === 'development') {
	const { createLogger } = require('redux-logger');
	const logger = createLogger({
		collapsed: true
	});
	middlewares.push(logger);
}

const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
