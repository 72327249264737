import {
  instance
} from './api-config'

import {
  awsinstance
} from './api-config'

import axios from 'axios';

import {API_URL, AWS_API_GATEWAY_URL} from '../env.json';

export const signIn = (data) => {
  return axios.post(`${API_URL}/users/login`, data, {
    headers: { 'Content-Type': 'application/json' }
  })
}

export const resetPassword = (data) => {
  return instance.post(`${API_URL}/users/password-reset`, data, {
    headers: { 'Content-Type': 'application/json' }
  })
}

export const fetchAllUsers = (id) => {
    return instance.get(`${API_URL}/users/all/${id}`)
}

export const addUser = (data) => {
    return instance.post(`${API_URL}/users/create`, data, {
      headers: { 'Content-Type': 'application/json' }
    })
}

export const editUser = (from_id, edit_id, data) => {
  return instance.post(`${API_URL}/users/edit/${from_id}/${edit_id}`, data, {
    headers: { 'Content-Type': 'application/json' }
  })
}

export const fetchUser = (from_username, username) => {
  return instance.get(`${API_URL}/users/fetch/${from_username}/${username}`)
}

export const deleteUsers = (from_user_id, user_id) => {
  return instance.get(`${API_URL}/users/delete/${from_user_id}/${user_id}`)
}

export const getUserfromApiGateway = () => {
  return awsinstance.get(`${AWS_API_GATEWAY_URL}/user-info`)
}


